document.addEventListener('turbolinks:load', function () {
    if ($('.permit_applications.new, .permit_applications.create, .projects.edit, .permit_applications.update, .permit_applications.edit').length === 0) {
        return false
    }

    // do not want to trigger this js if we are working on project edit form
    // it should only serve the permit applications form
    if ($('.permit_applications-form-section').length === 0) {
        return false
    }

    if ($('.projects.edit, .permit_applications.update, .permit_applications.edit').length !== 0) {
        processSelectionChanges();
    }

    function processSelectionChanges() {
        let constructionValue = $("#constructionSelectionTab input[type='radio']:checked").val();
        let projectValue = $("#projectTypeSelectionTab input[type='radio']:checked").val();
        if (constructionValue?.length > 0) {
            constructionSelection(constructionValue)
        }
        if (projectValue?.length > 0) {
            projectSelection(projectValue)
        }
    }

    $(document).on('change', "#constructionSelectionTab input[type='radio']", function () {
        let value = $(this).val()
        constructionSelection(value)
    })

    $(document).on('change', "#projectTypeSelectionTab input[type='radio']", function () {
        let value = $(this).val()
        projectSelection(value)
    })

    function constructionSelection(value) {
        var annualPermitSelection = $('#annual-permit-selection')
        var annualPermitRadio = $('#project_type_of_construction_annual_permit')
        if (value === 'Residential- Single Family') {
            selectService('residential')
            deSelectService('commercial')
            $(annualPermitSelection).addClass('d-none')
            $(annualPermitRadio).prop('checked', false)
        } else {
            selectService('commercial')
            deSelectService('residential')
            $(annualPermitSelection).removeClass('d-none')
        }
    }

    function projectSelection(value) {
        if (value === 'New Construction') {
            selectService('new_construction')
            deSelectService('renovation')
            deSelectService('annual_permit')
        } else if (value === 'Addition/ Renovation') {
            selectService('renovation')
            deSelectService('new_construction')
            deSelectService('annual_permit')
        } else if (value === 'Annual Permit') {
            selectService('annual_permit')
            deSelectService('new_construction')
            deSelectService('renovation')
        }
    }

    function selectService(service) {
        $("#" + service).addClass("selected");
    }

    function deSelectService(service) {
        $("#" + service).removeClass("selected");
    }
});